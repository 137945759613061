import Form               from 'antd/lib/form';
import Select             from 'antd/lib/select';
import Spin               from 'antd/lib/spin';
import {
	CONTRACT_ITERATION_STATUS_ACTIVATED,
	CONTRACT_ITERATION_STATUS_BLOCKED,
	CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT,
}                   from 'constants/ContractIterationStatuses';
import { observer }       from 'mobx-react';
import React              from 'react';
import { appStore }       from '../../stores';

function ContractFilter() {
	const {
		contractIterationStatusCollection,
		contractIterationStatusUrnFilter,
	} = appStore;

	const isLoading = (
		contractIterationStatusCollection.isLoading
	);

	if (isLoading) {
		return <Spin />;
	}

	return (
		<Form
			initialValues={{ urn: contractIterationStatusUrnFilter || [
				contractIterationStatusCollection.findBy('reference', CONTRACT_ITERATION_STATUS_ACTIVATED.reference)?.urn,
				contractIterationStatusCollection.findBy('reference', CONTRACT_ITERATION_STATUS_BLOCKED.reference)?.urn,
				contractIterationStatusCollection.findBy('reference', CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT.reference)?.urn
			] }}
			style={{ display: 'flex', gap: '0 1rem' }}
		>
			<Form.Item
				label="Statut"
				name="urn"
				style={{ width: 400 }}
			>
				<Select
					allowClear
					loading={isLoading}
					mode="multiple"
					onChange={urns => appStore.setContractIterationStatusUrnFilter(urns)}
					placeholder="Statut"
				>
					{contractIterationStatusCollection
						.filter(status => ['sent_to_client', 'activated', 'closed', 'blocked', 'terminated'].includes(status.reference))
						.map(status => {
							return (
								<Select.Option
									key={status.urn}
									value={status.urn}
								>
									{status.label}
								</Select.Option>
							);
						})
					}
				</Select>
			</Form.Item>
		</Form>
	);
}

export default observer(ContractFilter);
